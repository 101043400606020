import {
  addSourceProdWeight,
  updateSourceProdWeight,
  deleteSourceProdWeight,
  fetchSourceProdWeight,
} from 'api/setting';
import Pagination from '@/components/pagination';
import { mapState, mapActions } from 'vuex';
import GroupSelect from '@/components/GroupSelect';
import { selectProds } from 'api/product/overall';
import { fetchCountryList } from '@/api/DDJ/v3/country';

export default {
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      filterForm: {
        page: 1,
        pageSize: 20,
      },
      total: 0,
      postData: {},
      list: [],
      pkgList: [],
      options: {
        country: [{ label: '中国', value: 'zn' }],
      },
      loading: {
        list: false,
      },
      dialog: {
        visible: false,
        title: '--',
        type: '',
      },
      rules: {
        sourceId: [
          { required: true, message: 'sourceId不能为空' },
          { type: 'number', message: 'sourceId必须为数字值' },
        ],
        weight: [
          { required: true, message: 'weight不能为空' },
          { type: 'number', message: 'weight必须为数字值' },
          {
            type: 'number',
            min: 1,
            max: 100,
            message: 'weight的值必须在1到100之间',
            trigger: 'blur',
          },
        ],
        prod: [{ required: true, message: 'prod不能为空' }],
        country: [{ required: true, message: 'country不能为空' }],
      },
    };
  },
  computed: {
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    isDisabled() {
      // This computed property will return true if dialog.type is 'add', disabling the select input.
      return this.dialog.type === 'edit';
    },
  },
  mounted() {
    this.getList();
    this.getSourceIdsList();
    this.prodsList();
    this.getCountry();
  },
  methods: {
    ...mapActions('source', ['getSourceIdsList']),
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      this.loading.list = true;
      fetchSourceProdWeight(this.filterForm)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.loading.list = false;
          this.$message.error(e);
        });
    },
    // 获取prods
    prodsList() {
      selectProds().then((res) => {
        this.pkgList = res.result;
      });
    },
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    // 删除
    deletes(id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          deleteSourceProdWeight(id).then((res) => {
            if (res.code === 200) {
              this.$message.success('删除成功！');
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 打开弹窗
    openDialog(type, row) {
      this.dialog.type = type;
      this.dialog.title = type === 'add' ? '添加' : '编辑';
      this.dialog.visible = true;
      if (row) {
        this.postData = Object.assign({}, row);
      }
      if (this.dialog.type === 'add') {
        this.postData = {};
      }
    },
    // 提交弹窗
    submitDialog(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading.submitBtn = true;
          if (this.dialog.type === 'add') {
            addSourceProdWeight(this.postData).then((res) => {
              if (res.success === true) {
                this.$message.success('添加成功!');
                this.loading.submitBtn = false;
                this.closeDialog(formName);
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
          if (this.dialog.type === 'edit') {
            updateSourceProdWeight(this.postData).then((res) => {
              if (res.success === true) {
                this.$message.success('编辑成功!');
                this.loading.submitBtn = false;
                this.closeDialog(formName);
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    // 关闭弹窗
    closeDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialog.visible = false;
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
  },
};
