<template>
  <div class="whiteList">
    <!-- <div class="main-Title bgff"><h2>White List</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="60px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="13" :lg="13" :xl="13">
              <el-form :model="filterForm" inline>
                <el-form-item label="Source" class="mb5">
                  <group-select
                    v-model="filterForm.sourceId"
                    :data-source="sourcesList"
                    collapse-tags
                    :loading="sourceLoading"
                    clearable
                    reserve-keyword
                    filterable
                    placeholder="请选择"
                  />
                </el-form-item>
                <el-form-item label="Package" class="mb5">
                  <el-select v-model="filterForm.prod" clearable filterable>
                    <el-option
                      v-for="item in pkgList"
                      :key="item"
                      :value="item"
                      :label="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Country" prop="country">
                  <el-select
                    v-model="filterForm.country"
                    size="small"
                    placeholder="Please select"
                    style="width: 100%"
                    filterable
                    clearable
                  >
                    <el-option label="全部" value="" />
                    <el-option
                      v-for="item in options.country"
                      :key="item.countryCode"
                      :label="item.countryCode"
                      :value="item.countryCode"
                    />
                  </el-select>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search
                </el-button>
                <el-button type="primary" @click="openDialog('add')">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-table
              v-loading="loading.list"
              ref="tableRef"
              style="width:100%;min-height: 70vmin;"
              stripe
              border
              :data="list"
              highlight-current-row
              aria-setsize="mini"
              size="mini"
              class="mb10"
            >
              <el-table-column label="id" prop="id" align="center"></el-table-column>
              <el-table-column label="sourceId" prop="sourceId" align="center"></el-table-column>
              <el-table-column label="prod" prop="prod" align="center"></el-table-column>
              <el-table-column label="country" prop="country" align="center"></el-table-column>
              <el-table-column label="weight" prop="weight" align="center"></el-table-column>
              <el-table-column label="createBy" prop="createBy" align="center"></el-table-column>
              <el-table-column
                label="createTime"
                prop="createTime"
                align="center"
              ></el-table-column>
              <el-table-column label="updateBy" prop="updateBy" align="center"></el-table-column>
              <el-table-column
                label="updateTime"
                prop="updateTime"
                align="center"
              ></el-table-column>
              <el-table-column label="operation" align="center" width="200">
                <template slot-scope="scope">
                  <div style="display: flex; justify-content: center; gap: 3px;">
                    <el-button type="warning" size="mini" @click="openDialog('edit', scope.row)"
                      >update</el-button
                    >
                    <el-button type="danger" size="mini" @click="deletes(scope.row.id)"
                      >Delete</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="source" class="mb25" prop="sourceId">
              <group-select
                v-model="postData.sourceId"
                :data-source="sourcesList"
                collapse-tags
                :loading="sourceLoading"
                clearable
                reserve-keyword
                filterable
                placeholder="请选择"
                class="w100"
                :disabled="isDisabled"
              />
            </el-form-item>
            <el-form-item label="package" class="mb25" prop="prod">
              <el-select
                v-model="postData.prod"
                clearable
                filterable
                allow-create
                class="w100"
                :disabled="isDisabled"
              >
                <el-option
                  v-for="item in pkgList"
                  :key="item"
                  :value="item"
                  :label="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Country" prop="country">
              <el-select
                v-model="postData.country"
                size="small"
                placeholder="Please select"
                filterable
                style="width: 100%"
                :disabled="isDisabled"
              >
                <el-option label="全部" value="" />
                <el-option
                  v-for="item in options.country"
                  :key="item.countryCode"
                  :label="item.countryCode"
                  :value="item.countryCode"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="weight" prop="weight" class="mb25">
              <el-input
                v-model.number="postData.weight"
                placeholder="Please enter weight"
                type="number"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')" size="mini">取 消</el-button>
            <el-button
              type="primary"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import sourceProdWeight from '../controllers/setting/sourceProdWeight';

  export default {
    name: 'sourceProdWeight',
    ...sourceProdWeight,
  };
</script>

<style></style>
